<template lang="pug">
.InputContainer(:error="error ? 1 : 0")
  .AppTextField(:class="{'AppTextField--searchSize': searchSize, 'AppTextField--cannot-type': cannotType}")
    label.AppTextFieldLabel(:data-label-text="title" :class="{ 'AppTextFieldLabel--emptyTitle': !title }")
      div(:class="{'AppTextFieldInput': !isEmbedded, 'AppTextFieldInput--tel': isMasked }")
        slot(name="prepend")
        input(
          v-maska
          :data-maska="maska"
          :data-maska-tokens="maskaTokens"
          :data-maska-eager="maskaEager"
          :class="{[`AppTextFieldInnerInput AppTextFieldLabel--${type}`]: true, ['AppTextFieldEmbedded']: isEmbedded, ['is-adaptive-height']: isAdaptiveHeight}"
          :type="computedType"
          :value="modelValue"
          :placeholder="placeholder"
          ref="input"
          @input="handleInput"
          @blur="handleBlur"
          @focus="handleFocus"
          :maxlength="maxLength"
          :size="isEmbedded ? (modelValue.length - 3) : ''"
          :readonly="isEmbedded"
        )
      .show-password(v-if="type === 'password'" @click="togglePassword" :class="{'show-password--shown': showPassword}")
  .footer(v-if="!error && $slots.footer && !isEmbedded")
    slot(name="footer")
  .error(v-if="!withoutErrors && (error || !$slots.footer) && !isEmbedded")
    template(v-if="error")
      template(v-if="$slots.error")
        slot(name="error")
      template(v-else)
        <span>{{ error }}</span>
</template>

<script>
export default {
  name: 'AppTextField',
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      validator (value) {
        return ['password', 'text', 'tel', 'number', 'email'].includes(value)
      },
      default: 'text'
    },
    modelValue: {
      type: String,
      default: ''
    },
    searchSize: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: [String, Boolean],
      default: false
    },
    withoutErrors: {
      type: Boolean,
      default: false
    },
    cannotType: {
      type: Boolean,
      default: false
    },
    isEmbedded: {
      type: Boolean,
      default: false
    },
    maska: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: Infinity
    },
    maskaTokens: {
      type: String,
      default: ''
    },
    maskaEager: {
      type: Boolean,
      default: false
    },
    isAdaptiveHeight: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPassword: false,
    }
  },
  mounted() {
    console.log(this.$slots)
  },
  expose: ['focus', 'blur'],
  computed: {
    getMask () {
      if (this.type === 'tel') {
        return '+7##########'
      }
      return ''
    },
    isMasked () {
      return this.maska.length > 0;
    },
    computedType () {
      if (this.type === 'password' && this.showPassword) {
        return 'text'
      }
      return this.type
    }
  },
  methods: {
    togglePassword () {
      this.showPassword = !this.showPassword
    },
    focus () {
      this.$refs.input.focus()
    },
    blur () {
      this.$refs.input.blur()
    },
    handleInput(event) {
      let inputValue = event.target.value;

      if (this.type === 'number' && inputValue.length > this.maxLength) {
        inputValue = inputValue.slice(0, this.maxLength);
        event.target.value = inputValue;
      }

      if (inputValue.length <= this.maxLength) {
        this.$emit('update:modelValue', inputValue);
      }
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleFocus(event) {
      this.$emit('focus', event);
    },
  }
}
</script>

<style lang="scss" scoped>
.AppTextField {
  position: relative;

  &--cannot-type {
    pointer-events: none;
  }
}
.AppTextFieldInput {
  @include text-t4-medium;
  width: 100%;
  height: get-vw(72);
  border: get-vw(2) solid $grey-hover;
  border-radius: get-vw(18);
  padding-left: get-vw(40);
  cursor: pointer;
  position: relative;
  &:focus-visible,
  .AppTextField:focus-within & {
    border: get-vw(2) solid $primary;
    outline: none
  }
}

.AppTextField--searchSize input {
  @include text-t0-italic;
  flex: 1;
  position: relative;
  height: get-vw(74);
  color: $black;
  padding-left: get-vw(40);
  padding-right: get-vw(200);
  cursor: pointer;
  :focus-visible {
    border: get-vw(3) solid $stroke;
  }
  :hover {
    border: get-vw(3) solid $stroke;
  }

  &::placeholder {
    color: $grey-hover;
    @include text-t9;
  }
}

.AppTextField--searchSize {
  width: 100%;

  .AppTextFieldInput {
    height: get-vw(74);
    width: 100%;
  }

  .AppTextFieldInnerInput {
    padding: 0;
    width: 100%;
  }
}

.AppTextFieldLabel {
  @include text-t10;
  background-color: $white;
  color: $grey-hover;
  padding: 0;
  width: 100%;
  display: flex;
  position: relative;
  &::before {
    content: attr(data-label-text);
    position: absolute;
    left: get-vw(40);
    bottom: 2.5vw;
    background-color: white;
    z-index: 3;

    .AppTextField:focus-within & {
    color: $primary;
    }
  }

  &--emptyTitle::before {
    display: none;
  }
}
.AppTextField--tel {
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.InputContainer[error="1"] {

  .AppTextFieldInput {
    border: get-vw(2) solid $signal-red;
  }

  .AppTextFieldLabel {
    color: $signal-red;
    &:focus-within::before {
      color: $signal-red;
    }
  }
}

.error, .footer {
  @include text-t4-light-m("lg");
  color: $button-xs-text;
  margin: get-vw(0) get-vw(30);
  margin-top: get-vw(6);
  max-width: get-vw(412);
  display: flex;
  text-align: center;
  min-height: get-vw(32);
}

.show-password {
  position: absolute;
  top: calc(50% - get-vw(24) / 2);
  right: get-vw(26);
  background-image: url('/icons/eye-show.svg');
  height: get-vw(24);
  width: get-vw(24);
  background-position: center;
  background-repeat: no-repeat;
  background-size: get-vw(24);
  cursor: pointer;
  &--shown {
    background-image: url('/icons/eye-hide.svg');
  }
}

input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.AppTextFieldInput--tel {
  align-items: center;
  display: flex;

  .AppTextFieldEmbedded {
    cursor: pointer;
    width: fit-content;
  }

  input::-webkit-input-placeholder {
    padding-left: get-vw(6);
  }
}

.AppTextFieldInnerInput {
  border-top-right-radius: get-vw(26);
  border-bottom-right-radius: get-vw(26);
  border: none;
  outline: none;
  padding: 0;
  height: 100%;
  width: calc(100% - get-vw(6));
  @include text-t4-medium;

  &.is-adaptive-height {
    height: 100%;
  }
}

@include tablet {
  .AppTextFieldInput {
    width: 100%;
    height: get-vw(39, "md");
    padding-left: get-vw(19, "md");
    border-radius: get-vw(9, "md");
    border: get-vw(1, "md") solid $grey-hover;
    &:focus-visible,
    .AppTextField:focus-within & {
      border: get-vw(1, "md") solid $primary;
      outline: none
    }
  }
  .AppTextFieldLabel {
    @include input-badge("md");
    &::before {
      bottom: 3.2vw;
      left: get-vw(19, "md");
    }
  }
  .AppTextFieldInnerInput {
    height: 100%;
  }
  .InputContainer[error="1"] {

    .AppTextFieldInput {
      border: get-vw(1, "md") solid $signal-red;
    }

    .AppTextFieldLabel {
      color: $signal-red;
      &:focus-within::before {
        color: $signal-red;
      }
    }
  }

  .error, .footer {
    @include text-t11-m;
    margin: get-vw(0, "md") get-vw(18, "md");
    margin-top: get-vw(8, "md");
    max-width: get-vw(231, "md");
    min-height: get-vw(26, "md");
  }

  .AppTextFieldInnerInput {
    @include text-t10("md");
    height: 100%;
  }

  .AppTextField--searchSize input {
    @include text-t4-italic-m;
    height: get-vw(35, "md");
    color: $black;
    padding-left: get-vw(18, "md");
    padding-right: get-vw(18, "md");
    :focus-visible {
      border: get-vw(1, "md") solid $stroke;
    }
    :hover {
      border: get-vw(1, "md") solid $stroke;
    }
    &::placeholder {
      @include text-t4-regular-m;
    }
  }

  .AppTextField--searchSize {
    .AppTextFieldInput {
      height: get-vw(35, "md");
      width: 100%;
    }
  }

  .AppTextField--searchSize .AppTextFieldInnerInput {
    height: 100%;
  }
}

@include mobile {
  .AppTextFieldInput {
    width: get-vw(247, "sm");
    height: get-vw(39, "sm");
    padding-left: get-vw(18, "sm");
    padding-right: get-vw(18, "sm");
    border-radius: get-vw(9, "sm");
    border: get-vw(1, "sm") solid $grey-hover;
    display: flex;
    align-items: center;
    &:focus-visible,
    .AppTextField:focus-within & {
      border: get-vw(1, "sm") solid $primary;
      outline: none
    }
  }

  .AppTextFieldInnerInput {
    @include text-t10("sm");
    height: get-vw(18, "sm");
  }

  .AppTextFieldLabel {
    @include input-badge("sm");
    &::before {
      bottom: 8.9vw;
      left: get-vw(19, "sm");
    }
  }

  .InputContainer[error="1"] {

    .AppTextFieldInput {
      border: get-vw(1, "sm") solid $signal-red;
    }
  }

  .error, .footer {
    @include text-t11-m("sm");
    margin: 0 get-vw(18, "sm");
    margin-top: get-vw(8, "sm");
    max-width: get-vw(231, "sm");
    min-height: get-vw(26, "sm");
  }

  .AppTextField--searchSize .AppTextFieldInput {
    height: get-vw(36, "sm");
  }

  .AppTextField--searchSize input {
    @include text-t4-italic-m("sm");
    height: get-vw(36, "sm");
    :focus-visible {
      border: get-vw(1, "sm") solid $stroke;
    }
    :hover {
      border: get-vw(1, "sm") solid $stroke;
    }
    &::placeholder {
      @include text-t4-regular-m("sm");
    }
  }
}
</style>
